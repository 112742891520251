import React from "react";
// import { useTranslation } from "react-i18next";
import {
  PhoneIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import PageHeroStandard from "Components/PageHeroStandard";
import team from "Assets/images/teamtryton.png";
import { Helmet } from "react-helmet";

//import PageYellowImg from "Assets/images/page-yellow.png";
import "./Contact.css";
const styleCardConctact =
  "group flex flex-col    py-0  text-center lg:cursor-pointer transform 0  space-y-4  lg:hover:scale-105 ease-linear transition-all duration-100  items-center justify-center point";
const styleIconCard =
  "w-12 h-12 text-white bg-bluePresik px-3 rounded-full z-30 relative group-hover:bg-yellow-300 group-hover:text-gray-600";
export default function Contact() {
  return (
    <PageStandard buttonContactUs={false}>
      <Helmet>
        <title>Contacto | Presik S.A.S</title>
        <meta name="description" content="Escribenos y nuestro equipo Presik te respondera en breve" />
      </Helmet>
      <PageHeroStandard backgroundHero="imageRoundHeroText" image={team}>
        <PageTitle title="contact_us.title" />
        <PageDescription text="contact_us.description" />
      </PageHeroStandard>
      <div className="container py-20  mx-auto  px-20 bg-gray-50 z-40 shadow-md rounded-md max-w-6xl mt-10 mb-20">
        <div className="">
          <ul size="large flex" className="flex justify-between  ">
            <li className={styleCardConctact}>
              <EnvelopeIcon className={styleIconCard} />
              <a
                href="mailto:sales@presik.com"
                className="hover:text-black  lg:text-base pl-2 text-gray-600 font-semibold"
                target="_blank"
              >
                sales@presik.com
              </a>
            </li>
            <li className={styleCardConctact}>
              <PhoneIcon className={styleIconCard} />

              <span className="hover:text-black  lg:text-base pl-2 uppercase text-gray-600 font-semibold">
                (+57) 688 4208
              </span>
            </li>
            <li className={styleCardConctact}>
              <DevicePhoneMobileIcon className={styleIconCard} />
              <span className="hover:text-black  lg:text-base pl-2 uppercase text-gray-600 font-semibold">
                (+57) 324 5840949
              </span>
            </li>
            <li className={styleCardConctact}>
              <DevicePhoneMobileIcon className={styleIconCard} />
              <span className="hover:text-black  lg:text-base pl-2 uppercase text-gray-600 font-semibold">
                (+57) 324 5840917
              </span>
            </li>
          </ul>
        </div>
        {/*<div>
          <form class="w-full ">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 shadow-sm border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Nombre"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 shadow-sm border border-gray-200 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Apellido"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full px-3 mb-6 md:mb-0">
                <textarea
                  class="appearance-none block w-full bg-gray-200 text-gray-700 shadow-sm border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-32 p-4"
                  id="grid-city"
                  type="text"
                  placeholder="Escribe tu mensaje"
                />
              </div>
            </div>
          </form> 
          <div>
             <button
              type="submit"
              class="w-max mx-auto uppercase flex gap-4 items-center text-2 lg:text-xl mt-4 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black mt-20"
              href="/solutions/erp-colombia"
            >
              ENVIAR
            </button> 
          </div>
        </div>*/}
      </div>
      <div className="container mx-auto max-w-6xl"></div>
    </PageStandard>
  );
}
