import React, { useState, useRef, useEffect } from "react";
import { PhoneIcon } from "@heroicons/react/24/solid";



const WhatsappBtn = () => {
const [isClicked, setIsClicked] = useState(false);
const buttonRef = useRef(null);

const handleClickOutside = (event) => {
	if (buttonRef.current && !buttonRef.current.contains(event.target)) {
	setIsClicked(false);
	}
};

const handleClick = () => {
	setIsClicked(!isClicked);
};

useEffect(() => {
	document.addEventListener("click", handleClickOutside);

	return () => {
	document.removeEventListener("click", handleClickOutside);
	};
}, []);

return (
	<div
		className={`flex justify-center z-50 border-2 border-white cursor-pointer items-center h-12 w-12 rounded-full bg-green-600 hover:bg-gray-900 fixed bottom-3 right-3 icon-style ${isClicked ? "clicked" : ""}`}
		// onClick={handleClick}
		ref={buttonRef}
	>
		
		<div>
			<a href="https://wa.me/573245840949" target="_blank" id="btn-call-whatsapp"> 
		<PhoneIcon  className="w-6 text-white flex justify-center items-center duration-100 text-3xl" />
			</a>
		</div>
		{/* {isClicked && (
		<div className="font-roboto">
			<a href="https://wa.me/573214269824" className="fixed ease-in bg-yellow-400 text-black right-20 bottom-14 py-2 px-4 w-52 cursor-pointer rounded-2xl text-sm hover:scale-110 transition-all transform hover:text-black">
			¿NECESITAS SOPORTE?
			</a>
			<a href="https://wa.me/573245840949" className="fixed ease-in bg-gray-900 text-white right-20 -bottom-3 py-2 px-5 cursor-pointer w-56 rounded-2xl text-sm hover:scale-110 transition-all transform hover:text-white">
			¿INTERESADO EN NUESTRAS SOLUCIONES?</a>
		</div>
		)} */}
	</div>
);
};

export default WhatsappBtn;