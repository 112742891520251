import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import Card from "Components/Card";
import "./Tryton.css";
import dataModules from "Home/data";
import PageHeroStandard from "Components/PageHeroStandard";
import imgMovilPresik from "../Assets/images/phonepresik.png";
import imageWhytryton from "../Assets/images/future.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import Section from "Components/Section";
import WhyPresik from "./WhyPresik";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { Helmet } from "react-helmet";

function TrytonSolutions() {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  function getCardImage(m) {
    return (
      <div key={m.name} className="mb-10 flex-grow">
        <Card {...m} key={m.name} />
      </div>
    );
  }

  const dataFilterIcons = () => {
    const dataIcons = dataModules
      .map((objeto) => {
        const { icon, color } = objeto;
        return { icon, color };
      })
      .slice(0, 4);

    return dataIcons;
  };

  return (
    <PageStandard fullWidth={true}>
      <Helmet>
        <title>ERP COLOMBIA | Presik S.A.S</title>
        <meta name="description" content="Nuestro ERP en Colombia no requiere de interfases , es completamente modulable a las necesidades del negocio." />
      </Helmet>
      <PageHeroStandard
        backgroundHero="heroHomeDividerLine"
        image={imgMovilPresik}
        data={dataFilterIcons()}
      >
        <PageTitle title="solutions.title" textAling="text-left" />

        <PageDescription
          text="solutions.description_title"
          textAling="text-left"
        />
      
        <a
          className="font-roboto w-max uppercase flex gap-4 items-center text-black lg:text-xl mt-8 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
          href="#more-modules"
        >
          Ver módulos ERP{" "}
          <ArrowLongRightIcon className="h-8 text-black hover:scale-125" />
        </a>
      </PageHeroStandard>
      
      <div className="container mx-auto px-10 md:pb-32 pb-10  space-y-12 pt-20" id="more-modules">
        <h2 class="  font-semibold md:text-4xl text-2xl lg:w-full  relative text-blue-presik  max-w-3xl mx-auto font-lato ">
          Con{" "}
          <span className="font-semibold bg-gray-900 uppercase text-white px-2">
            Tryton
          </span>{" "}
          será más fácil que tu empresa lleve una gestión{" "}
          <span className="font-semibold bg-yellow-300 px-2 mt-2 md:text-4xl text-2xl leading-none ">
            centralizada de cada aspecto de tu negocio.{" "}
          </span>
        </h2>

        <div className=" gap-x-12  grid grid-cols-1 md:grid-cols-3">
          {dataModules.map((m) => getCardImage(m))}
        </div>
      </div>

      <Section
        bgColor="bg-gradient-to-tl from-gray-900 via-gray-900 to-gray-700"
        // marginBottom="mb-32"
        spaceX="md:px-5 "
        spaceTop="pt-0 pb-10 md:py-40"
      >
        <WhyPresik image={imageWhytryton} />
      </Section>
    </PageStandard>
  );
}

export default TrytonSolutions;
