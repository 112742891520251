import logoPresik from "Assets/images/logo-presik.png";

const jobs = [
  {
    id: 2,
    slug: "vacante-jefe-financiero",
    title: "VACANTE JEFE FINANCIERO/A",
    logo: logoPresik,
    salary: "A convenir",
    typeContract: "Tiempo completo",
    type: "Presencial",
    place: "Bucaramanga",
    contact: {
      phone: "+57 301 2457967",
      email: "ceo@presik.com",
      address: "ceo@presik.com",
    },
    shortDesc: `Empresa Startup Tech está buscando una persona que lidere el área financiera. Se busca Contador/a con experiencia o estudios de especialización en el área Financiera, Ing. Industrial o Ingeniero/a Financiero/a.`,
    longDesc: `
      <p>Empresa Startup Tech está en búsqueda de un/a JEFE FINANCIERO/A para liderar el área financiera. Se requiere un perfil con formación en Contaduría Pública, Ingeniería Industrial o Ingeniería Financiera, con experiencia o estudios de especialización en el área financiera.</p>
      <h3 class="font-semibold my-4">Funciones:</h3>
      <ul class="my-4 list-disc list-inside">
        <li>Gestionar y hacer seguimiento al presupuesto de la empresa.</li>
        <li>Hacer seguimiento a las metas de cartera.</li>
        <li>Realizar flujos de caja y análisis predictivos.</li>
        <li>Controlar costos y gastos de la empresa.</li>
        <li>Gestionar operaciones, comunicación y negociación con bancos y mecanismos de financiación.</li>
        <li>Realizar análisis financiero de la empresa y presentar indicadores a Gerencia.</li>
        <li>Analizar costos de proyectos y elaborar estados de resultados por proyecto.</li>
      </ul>
      <h3 class="font-semibold my-4">Habilidades:</h3>
      <ul class="my-4 list-disc list-inside">
        <li>Dominio avanzado de Excel.</li>
        <li>Capacidad de análisis de datos.</li>
        <li>Persona altamente proactiva.</li>
        <li>Excelentes habilidades de gestión y organización.</li>
        <li>Espíritu persistente y orientado a resultados.</li>
      </ul>
      <p><strong>Ubicación:</strong> Trabajo presencial en Bucaramanga. En caso de residir en otra ciudad, se requiere disponibilidad para radicarse en Bucaramanga.</p>
      <p class="mt-4"><strong>Ofrecemos:</strong> Salario a convenir según experiencia y perfil profesional.</p>
      <p>CV enviar a: <a href="mailto:ceo@presik.com">ceo@presik.com</a></p>
    `,
  },
];

const jobsCustomer = [];

export { jobs, jobsCustomer };
