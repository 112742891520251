import React from "react";
import { useTranslation } from "react-i18next";
import ModuloEspecializados from "./ModuloEspecializado";
import hotelImg from "../Assets/images/home-hotel-section.webp";
import agroImg from "../Assets/images/gestion-agricola.webp";
import vigilanciaImg from "../Assets/images/vigilance-section.webp";
import labImg from "../Assets/images/software-laboratorio.webp";
import RestaurantImg from "../Assets/images/restaurantes.jpg"
import ManufactureImg from "../Assets/images/apps/software-manufactura.webp"
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

const Modules = (props) => {

  const { t } = useTranslation();

  return (
    <>
    <div id="modules-section" className="md:pt-20 flex flex-col justify-center mb-0 pb-10 pt-10  lg:pb-20 lg:px-0 px-5  mx-auto  p-2 bg-bluePresik w-full">
      <h2 className="font-lato text-white lg:text-6xl text-4xl uppercase font-semibold  text-center">
        MÓDULOS <span className="font-medium">ESPECIALIZADOS</span>
      </h2>
      <p className="font-roboto lg:text-2xl text-lg text-white font-light pb-10 md:pb-2 text-center mt-8 max-w-6xl mx-auto">
      Sabemos que más allá de un ERP, tu empresa necesita módulos enfocados en el core de tu negocio que estén perfectamente sincronizados con su CRM.
      </p>

      <span className="text-yellow-300 text-center lg:text-2xl text-lg mt-5 font-roboto">¡Ahora podrás conocer cada uno de ellos!</span>
      <ChevronDoubleDownIcon className="w-10 mx-auto text-white animate-pulse mt-4" />
    </div>

    {/**modules */}
    
    <div>
      <ModuloEspecializados
          title="HOTELES"
          text="Una solución PMS que complementa ERP y CRM orientado a optimizar la operación de tu hotel y a maximizar la satisfacción del cliente."
          image={hotelImg}
          buttonText={t("home.know_more")}
          buttonLink="/solutions/software-hoteles"
          inverted={false}
        />
        <ModuloEspecializados
        title="GESTIÓN AGRÍCOLA"
        text="Sistema de gestión agrícola y postcosecha, optimiza tus labores, controla inventarios, monitorea cosechas, control de calidad, planificación de cultivo, programación de labores, control fitosanitarios, registro ICA, control de inventario e insumos."
        image={agroImg}
        buttonText={t("home.know_more")}
        buttonLink="/solutions/erp-colombia"
        inverted={true}
      />
        <ModuloEspecializados
        title="EMPRESAS DE VIGILANCIA"
        text="Es la nueva solución para la gestión de empresas de vigilancia única en el mercado, potente, abierta y flexible. Capacidad para programar turnos, integración de nómina y contabilidad y liquidación de horas extra."
        image={vigilanciaImg}
        buttonText={t("home.know_more")}
        // buttonLink="/modulos/vigilancia"
        buttonLink="/solutions/erp-colombia"
        inverted={false}
      />
        <ModuloEspecializados
        title="LABORATORIO CLÍNICO"
        text="Gestión de laboratorio clínico, integración con facturación, generación de órdenes de servicio, tipos de análisis, gestión de calidad, gestión de copago y generación de RIPS."
        image={labImg}
        buttonText={t("home.know_more")}
        // buttonLink="/modulos/laboratorio"
        buttonLink="/solutions/erp-colombia"
        inverted={true}
      />
            <ModuloEspecializados
        title="RESTAURANTES"
        text="En la gestión moderna de un restaurante, la implementación de un sistema integral que incluya un Punto de Venta (POS) es crucial para optimizar las operaciones diarias. Este sistema de restaurantes abarca desde la toma de pedidos hasta la facturación, facilitando una gestión eficiente y mejorando la experiencia del cliente."
        image={RestaurantImg}
        buttonText={t("home.know_more")}
        // buttonLink="/modulos/laboratorio"
        buttonLink="/solutions/erp-colombia"
        inverted={false}
      />
           <ModuloEspecializados
        title="SOFTWARE PARA MANUFACTURAS"
        text="En la industria manufacturera, la implementación de un sistema integral es esencial para mejorar la eficiencia y la calidad en todas las fases del proceso productivo. Un sistema de manufactura abarca desde la planificación y el control de producción hasta la gestión de inventarios y la calidad del producto final."
        image={ManufactureImg}
        buttonText={t("home.know_more")}
        // buttonLink="/modulos/laboratorio"
        buttonLink="/solutions/erp-colombia"
        inverted={true}
      />
    </div>
    </>
  );
};

export default Modules;
